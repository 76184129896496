




























































































































































































































import Vue from 'vue'
// import {Watch} from 'vue-property-decorator'
import Component from 'vue-class-component'

@Component({})
export default class ChainCustomerCenter extends Vue {
  queryCustomer = ''
  queryInvoice = ''
  queryProxyOrder = ''
  querySite = ''
  customerResults = []
  invoiceResults = []
  siteResults = []
  sites = []
  isLoading = false
  mode = ''
  proxyOrderMessage = ''

  mounted() {
    this.$axios.get('/v3/chain/sites').then((response) => {
      this.sites = response.data.data
      this.searchSite()
    })
  }

  searchCustomer() {
    this.isLoading = true
    this.mode = 'customers'
    this.customerResults = []
    const url = '/v3/chain/customers?query=' + encodeURIComponent(this.queryCustomer)
    this.$axios.get(url).then((response) => {
      const data = response.data.data
      // console.log('data:', data)
      this.customerResults = data || []
      this.isLoading = false
    })
  }

  searchInvoice() {
    this.isLoading = true
    this.mode = 'invoices'
    this.invoiceResults = []
    const url = '/v3/chain/invoices?query=' + encodeURIComponent(this.queryInvoice)
    this.$axios.get(url).then((response) => {
      const data = response.data.data
      // console.log('data:', data)
      this.invoiceResults = data || []
      this.isLoading = false
    })
  }

  searchProxyOrder() {
    this.isLoading = true
    this.mode = 'proxyOrders'
    this.invoiceResults = []
    const url = '/v3/chain/proxy-order?id=' + encodeURIComponent(this.queryProxyOrder)
    this.$axios
      .get(url)
      .then((response) => {
        this.proxyOrderMessage = this.$t('Redirecting to order...').toString()
        this.isLoading = false
        const data = response.data.data
        window.location.href = data.url
      })
      .catch(() => {
        this.isLoading = false
        this.proxyOrderMessage = this.$t('No such order').toString()
      })
  }

  searchSite() {
    this.mode = 'sites'
    const results = []
    const parts = this.querySite.trim().toLowerCase().split(' ')
    for (let i = 0; i < this.sites.length; i++) {
      const haystack = (this.sites[i].name + ' ' + this.sites[i].city).toLowerCase()
      let skip = false
      for (let j = 0; j < parts.length; j++) {
        if (haystack.indexOf(parts[j]) === -1) {
          skip = true
        }
      }
      if (!skip) {
        results[results.length] = this.sites[i]
      }
    }
    this.siteResults = results
  }

  getSiteName(id) {
    for (let i = 0; i < this.sites.length; i++) {
      if (this.sites[i].id === id) {
        return this.sites[i].name
      }
    }
    return ''
  }

  noCustomerLink() {
    alert('Got no data connected to this customer, so do not know which site to take you to')
  }

  noCarLink() {
    alert('Got no data connected to this car, so do not know which site to take you to')
  }
}
